export const contactsRoutes = [
  {
    path: '/account/:id/contacts',
    component: () => import('@/layouts/Main.vue'),
    meta: { requiredPermission: 'CONTACT_READ' },
    children: [
      {
        path: 'personal',
        name: 'personal-contacts-list',
        component: () => import('./views/PersonalList.vue'),
        meta: { requiredPermission: ['CONTACT_READ'] },
      },
      {
        path: 'corporative',
        name: 'corporative-contacts-list',
        component: () => import('./views/CorporativeList.vue'),
        meta: { requiredPermission: ['CONTACT_READ'] },
      },
      {
        path: 'lists',
        name: 'custom-lists',
        component: () => import('./views/CustomLists.vue'),
      },
      {
        path: 'personal/:contactId',
        name: 'personal-detail',
        component: () => import('./views/PersonalDetail.vue'),
      },
      {
        path: 'personal/edit/:contactId',
        name: 'personal-edit-personal-data',
        component: () => import('./views/PersonalEditPersonalData.vue'),
      },
      {
        path: 'personal/edit/:contactId/professional',
        name: 'personal-edit-professional-data',
        component: () => import('./views/PersonalEditProfessionalData.vue'),
      },
      {
        path: 'personal/edit/:contactId/professional/:institutionId/delete',
        name: 'delete-professional-data',
        component: () => import('./views/DeleteProfessionalData.vue'),
      },
      {
        path: 'personal/delete/:contactId',
        name: 'delete-personal-contact',
        component: () => import('./views/PersonalDeleteData.vue'),
      },
      {
        path: 'personal/anonymize/:contactId',
        name: 'anonymize-personal-data',
        component: () => import('./views/PersonalAnonymizeData.vue'),
      },
      {
        path: 'corporative/:contactId',
        name: 'corporative-detail',
        component: () => import('./views/CorporativeDetail.vue'),
      },
      {
        path: 'corporative/:contactId/edit',
        name: 'corporative-edit-general-data',
        component: () => import('./views/CorporativeEditGeneralData.vue'),
      },
      {
        path: 'corporative/:contactId/edit/relationships',
        name: 'corporative-edit-relationships',
        component: () => import('./views/CorporativeEditRelationships.vue'),
      },
      {
        path: 'settings/custom-fields',
        name: 'custom-fields-settings',
        component: () => import('./views/CustomFieldsSettings.vue'),
      },
      {
        path: 'settings/required-fields',
        name: 'required-fields-settings',
        component: () => import('./views/RequiredFieldsSettings.vue'),
      },
      {
        path: 'custom-table',
        name: 'contacts-manage-table',
        component: () => import('./views/ManageTableColumns.vue'),
      },
    ],
  },
]
