import eventsGreyIcon from '@/assets/icons/grey/events.svg'
import contactsGreyIcon from '@/assets/icons/grey/contacts.svg'
import teamGreyIcon from '@/assets/icons/grey/team.svg'
import financeGreyIcon from '@/assets/icons/grey/finance.svg'
import templatesGreyIcon from '@/assets/icons/grey/templates.svg'
import eventsWhiteIcon from '@/assets/icons/white/events.svg'
import contactsWhiteIcon from '@/assets/icons/white/contacts.svg'
import teamWhiteIcon from '@/assets/icons/white/team.svg'
import financeWhiteIcon from '@/assets/icons/white/finance.svg'
import templatesWhiteIcon from '@/assets/icons/white/templates.svg'

export const topNavIcons = {
  events: [eventsGreyIcon, eventsWhiteIcon],
  contacts: [contactsGreyIcon, contactsWhiteIcon],
  members: [teamGreyIcon, teamWhiteIcon],
  finance: [financeGreyIcon, financeWhiteIcon],
  templates: [templatesGreyIcon, templatesWhiteIcon],
}
