export const customFieldsRoutes = [
  {
    path: '/account/:id/custom-fields',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: 'select-type',
        name: 'select-custom-field-type',
        component: () => import('./views/SelectType.vue'),
      },
      {
        path: 'new',
        name: 'create-custom-field',
        component: () => import('./views/Create.vue'),
      },
    ],
  },
]
