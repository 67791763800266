import { pinia } from '@/plugins/pinia'
import { Pinia } from 'pinia'
import { Ref, ref } from 'vue'
export const globalLoading = ref<boolean>(false)

type UseLoadingOptions = {
  global?: boolean
  store?: string
}

export const useLoading = (
  promise?: undefined | ((loading?: Ref<boolean>) => Promise<unknown>),
  options?: UseLoadingOptions
) => {
  const loading = ref<boolean>(false)
  const { global, store = 'account' } = options || {}

  if (promise) {
    loading.value = true

    if (global) {
      globalLoading.value = true
    }

    if (store && pinia.state.value[store as keyof Pinia]) {
      pinia.state.value[store as keyof Pinia].loading = true
    }

    promise(loading).finally(() => {
      loading.value = false
      if (global) globalLoading.value = false
      if (store && pinia.state.value[store as keyof Pinia]) {
        pinia.state.value[store as keyof Pinia].loading = false
      }
    })
  }

  return {
    loading,
    globalLoading,
  }
}
