import {
  Member,
  MemberPermissionUpdate,
  MemberRequestParams,
} from '@/modules/member/types/members'
import { CRUDService } from '@/services/CRUD'
import { FormError } from '@/types/error'
import { ListStatus } from '../types/invite'
import { Permissions } from '../types/role'

export class MemberService extends CRUDService {
  static async updateMemberRole({
    corporateId,
    memberId,
    roleId,
  }: MemberPermissionUpdate) {
    return await this.handlePromise<void>(
      this.api.put(
        `/company/${corporateId}/user/${memberId}/permission/preset/${roleId}`
      )
    )
  }

  static async get() {
    return await this.handlePromise<Member>(this.api.get<Member>('/account'))
  }

  static async getMembers({
    id,
    size = '20',
    sort,
    page = 0,
    searchTerm = '',
    customRoles,
    defaultRoles,
    emptyRole,
    listAllPermissions,
  }: MemberRequestParams) {
    return await this.handlePromise<Member[]>(
      this.api.get<Member[]>(`/company/${id}/user`, {
        params: {
          sort: Array.isArray(sort) ? sort.join('&sort=') : '',
          size,
          page,
          'searchTerm.contains': searchTerm,
          'permissionPreset.custom.equals':
            customRoles || (defaultRoles ? !defaultRoles : ''),
          'permissionPreset.permission.in': listAllPermissions?.length
            ? listAllPermissions.join(',')
            : null,
          'permissionPreset.id.specified': emptyRole ? !emptyRole : '',
        },
      })
    )
  }

  static async getMemberPermission(id: string, memberId: string) {
    return await this.handlePromise(
      this.api.get<Permissions>(`/company/${id}/user/${memberId}/permission`)
    )
  }

  static async updateMemberPermission(
    id: string,
    memberId: string,
    payload: Permissions
  ) {
    return await this.handlePromise<Permissions, FormError>(
      this.api.put<Permissions>(
        `/company/${id}/user/${memberId}/permission`,
        payload
      )
    )
  }

  static async sendMemberInviteForCorporativeAccount(
    id: string,
    emails: string[]
  ) {
    //To Do: send the whole list of emails(waiting for backend implementation)
    return await this.handlePromise<ListStatus>(
      this.api.post(`/company/${id}/invite/send`, { emails })
    )
  }

  static async deleteMember(id: string, memberId: string) {
    return await this.handlePromise(
      this.api.delete(`/company/${id}/user/${memberId}`)
    )
  }

  static async sendRequestedEmail(
    id: string,
    requestId: string,
    email: string
  ) {
    return await this.handlePromise<void>(
      this.api.post(`/company/${id}/invite/${requestId}`, email, {
        headers: { 'Content-Type': 'text/plain' },
      })
    )
  }
}
