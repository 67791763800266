<template>
  <router-view />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

import { useCorporativeAccount } from '@/store/corporative'
import useStore from '@/store/auth'
import { useLoading } from './composables/useLoading'
import { globalPermissionsGuard } from '@/utils/globalPermissionsGuard'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

const corporativeAccountStore = useCorporativeAccount()
const { getCorporates } = corporativeAccountStore

const authUtore = useStore()
const { getUserInfo } = authUtore
const { user } = storeToRefs(authUtore)
const { locale } = useI18n({ useScope: 'global' })

globalPermissionsGuard()

onMounted(async () => {
  await getUserInfo()
  locale.value = user.value?.locale

  useLoading(async () => await getCorporates())
})
</script>

<style>
body,
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.dash:before {
  content: '|';
  color: rgb(--var(v-green));
  color: #8dc63f;
  margin-right: 0.5rem;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(85, 85, 85, 0.35);
  border-radius: 3px;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-right: 1px solid #c5c5c5;
}

.v-data-table-column--fixed {
  font-weight: bold;
  text-overflow: ellipsis;
  max-width: 300px;
}

.v-table .v-table__wrapper > table > tbody > tr > td {
  border-bottom: none !important;
  border-right: 2px solid #d8d8d8;
  max-width: 300px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.v-table .v-table__wrapper > table > tbody > tr:nth-child(even) > td {
  background-color: #efefef !important;
}

.v-table .v-table__wrapper > table > tbody > tr:nth-child(odd) > td {
  background-color: white !important;
}

table {
  border-bottom: 1px solid #d8d8d8;
  word-break: break-all;
  border-collapse: inherit !important;
}

.v-table {
  background-color: transparent !important;
}

.v-pagination__item--is-active {
  background-color: #8dc63f !important;
}

.v-pagination .v-btn:hover {
  background-color: #8dc63f !important;
}

.v-btn:hover > .v-btn__overlay {
  opacity: 0 !important;
}
</style>
