import { useLoading } from '@/composables/useLoading'
import { useCorporativeAccount } from '@/store/corporative'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

export const globalPermissionsGuard = () => {
  const route = useRoute()
  const router = useRouter()
  const toast = useToast()
  const { t } = useI18n()

  const corporativeAccountStore = useCorporativeAccount()
  const { getCorporate, getCorporates } = corporativeAccountStore
  const { corporate, corporates, accountPermissions, isAccountOwner } =
    storeToRefs(corporativeAccountStore)
  watch(route, () => {
    useLoading(
      async () => {
        if (!corporates.value) getCorporates()
        if (route.params.id) {
          if (!corporate.value?.id) await getCorporate()
          if (isAccountOwner.value) return
          if (
            (route.meta.isAdmin && !isAccountOwner.value) ||
            (typeof route.meta.requiredPermission == 'string' &&
              !accountPermissions.value.includes(
                route.meta.requiredPermission
              )) ||
            (route.meta.requiredPermission &&
              typeof route.meta.requiredPermission != 'string' &&
              !route.meta.requiredPermission?.find((p) =>
                accountPermissions.value.includes(p)
              ))
          ) {
            toast.error(t('permissions.blockedAccess'))

            if (route.name != 'home') router.back()
          }
        }
      },
      { global: true }
    )
  })
}
