import { keycloakInstance } from '@/security/instance'
import axios, { AxiosError, AxiosInstance } from 'axios'
import qs from 'qs'
export class API {
  private static instance: AxiosInstance

  private constructor() {
    return axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}/api`,
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false, skipNulls: true })
      },
    })
  }

  public static getInstance(): AxiosInstance {
    if (!API.instance) {
      API.instance = new API() as AxiosInstance
      API.instance.interceptors.request.use(async (config) => {
        try {
          if (!keycloakInstance.token) return config
          await keycloakInstance.updateToken(30)
          config.headers['Authorization'] = `Bearer ${keycloakInstance.token}`
        } catch (err) {
          console.error(err)
          if (keycloakInstance)
            keycloakInstance.login({
              redirectUri: import.meta.env.VITE_BASE_URL,
            })
        }
        config.withCredentials = true
        return config
      })
      API.instance.interceptors.response.use(
        (response) => response,
        async (error: AxiosError) => {
          const { response } = error

          if (response?.status == 401) {
            await keycloakInstance.logout()
            keycloakInstance.login({
              redirectUri: import.meta.env.VITE_BASE_URL,
            })
          }
          return Promise.reject(error)
        }
      )
    }

    return API.instance
  }
}
