<script setup lang="ts">
import { RuleExpression, useField } from 'vee-validate'
import { MaybeRef, computed } from 'vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    name: string
    type: string
    rules?: MaybeRef<RuleExpression<unknown>>
    label?: string
    items?: Array<unknown>
    labelField?: string
    valueField?: string
    disabled?: boolean
    checked?: boolean
    defaultValue?: string
    loading?: boolean
    icon?: string
    leftPosition?: boolean
    onClickButton?: () => void
    decimalPlaces?: number
    hideErrorMessage?: boolean
  }>(),
  {
    type: 'text',
    labelField: 'name',
    valueField: 'value',
    items: undefined,
    rules: '',
    label: '',
    select: false,
    checked: false,
    defaultValue: undefined,
    icon: '',
    leftPosition: false,
    onClickButton: undefined,
    decimalPlaces: undefined,
    hideErrorMessage: false,
  }
)

const { value, errorMessage, setValue, meta } = useField(
  () => props.name,
  props.rules,
  { initialValue: props.defaultValue || undefined, validateOnMount: false }
)

const label = computed(
  () => `${props.label}${props.label && (meta.required ? ' *' : '')}`
)

const emit = defineEmits(['change', 'country-code', 'validate'])

function handleChange(event: Event) {
  const value = (event?.target as HTMLInputElement)[
    type == 'checkbox' ? 'checked' : 'value'
  ]

  emit('change', type === 'number' ? Number(value) : value)
  setValue(type === 'number' ? Number(value) : value)
}

const { type } = props
</script>
<template>
  <div id="form-input" class="flex w-full flex-col" :class="$attrs.class">
    <Select
      v-if="type === 'select'"
      v-bind="{ ...$attrs }"
      v-model="value"
      :name="name"
      :disabled="loading || disabled"
      :checked="checked"
      :label="label"
      :items="props.items"
      :error="errorMessage ? true : false"
      :label-field="props.labelField"
      :value-field="props.valueField"
      @input="handleChange"
    />
    <PhoneInput
      v-else-if="type === 'phone'"
      v-bind="{ ...$attrs }"
      v-model="value"
      :name="name"
      :type="type"
      :label="label"
      :disabled="loading || disabled"
      :checked="checked"
      :error="errorMessage ? true : false"
      @input="
        (value: string) => {
          setValue(value)
          $emit('change', value)
        }
      "
      @country-code="(value: string) => $emit('country-code', value)"
    />
    <Input
      v-else
      v-bind="{ ...$attrs }"
      v-model="value"
      :multiline="props.type === 'textarea'"
      :name="name"
      :disabled="loading || disabled"
      :checked="checked"
      :type="type"
      :label="label"
      :error="errorMessage ? true : false"
      :icon="icon"
      :left-position="leftPosition"
      :on-click-button="onClickButton"
      :decimal-places="props.decimalPlaces"
      @input="handleChange"
    />
    <div
      v-if="type != 'phone' && errorMessage && !hideErrorMessage"
      id="error-message"
      class="mb-1 h-[1rem] pl-1 text-xs text-error"
    >
      {{ $t(errorMessage) }}
    </div>
  </div>
</template>
