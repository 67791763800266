<script setup lang="ts">
import { useCorporativeAccount } from '@/store/corporative'
import { storeToRefs } from 'pinia'
import { onMounted, ref, watch } from 'vue'
const { permissions } = defineProps<{ permissions: string[] }>()

const isAllowed = ref(false)

const corporativeAccountStore = useCorporativeAccount()
const { corporate, isAccountOwner, accountPermissions } = storeToRefs(
  corporativeAccountStore
)
const handler = () => {
  if (!corporate) return
  if (isAccountOwner.value || !permissions.length) {
    isAllowed.value = true
    return
  }

  isAllowed.value = Boolean(
    accountPermissions.value.find((p: string) => permissions.includes(p))
  )
}

onMounted(handler)
watch(corporate, handler)
</script>
<template>
  <slot :is-allowed="isAllowed" />
</template>
