<script setup lang="ts" generic="T">
import searchIcon from '@/assets/icons/grey/search.svg'
import Input from '@/components/Input.vue'
import { computed, onUnmounted, ref, toRefs } from 'vue'

import {
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Combobox as HuiCombobox,
} from '@headlessui/vue'

defineOptions({
  inheritAttrs: false,
})
const props = withDefaults(
  defineProps<{
    id?: string
    optionsId?: string
    label: string
    required?: boolean
    items: T[] | null
    modelValue?: string | number | string[]
    labelField?: keyof T | string
    valueField?: keyof T | string
    loading?: boolean
    error?: boolean
    placeholder?: string
    multiple?: boolean
    initialValue?: string | number | Array<unknown>
    disabled?: boolean
  }>(),
  {
    id: '',
    optionsId: '',
    required: false,
    labelField: 'label',
    valueField: 'value',
    modelValue: undefined,
    loading: false,
    error: false,
    placeholder: '',
    messageAfterOptions: '',
    multiple: false,
    initialValue: undefined,
    disabled: false,
  }
)

const { label, items } = toRefs(props)

const emit = defineEmits([
  'change',
  'click',
  'update:modelValue',
  'select',
  'submit',
  'search',
])

const selectedItem = ref(
  props.multiple
    ? [...(Array.isArray(props.initialValue) ? props.initialValue : [])]
    : props.initialValue
)

const handleOptionClick = (item: (typeof items.value)[number]) => {
  value.value = ''
  emit('click', item)
}

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const handleSubmit = () => {
  emit('submit', selectedItem.value)
}

const handleDelete = () => {
  if (!props.multiple) return
  if (value.value && String(value.value).length > 0) {
    String(value.value).slice(0, -1)
  } else if ((selectedItem.value as Array<unknown>).length) {
    ;(selectedItem.value as Array<unknown>).pop()
  }
}

function handleInput(event?: Event) {
  emit('search', (event?.target as HTMLInputElement)?.value)
  value.value = (event?.target as HTMLInputElement)?.value
}

onUnmounted(() => {
  value.value = ''
})
</script>

<template>
  <HuiCombobox
    v-slot="{ disabled: isDisabled }"
    v-model="selectedItem"
    :multiple="multiple"
    :disabled="props.disabled"
    @update:model-value="(v) => $emit('select', v)"
  >
    <div
      class="relative w-full text-center"
      :class="{ 'opacity-50': isDisabled }"
    >
      <div
        v-if="multiple"
        class="focus:text-black-2 flex max-h-10 w-full rounded-xl border border-grey-4 bg-white p-1 pl-2 text-black-0 outline-none transition-all duration-100"
      >
        <div class="flex w-full overflow-hidden">
          <div
            v-if="(selectedItem as Array<unknown>)?.length > 0"
            class="flex flex-row flex-nowrap"
          >
            <Chip
              v-for="chip in selectedItem"
              :key="(chip as object)[props.valueField as keyof typeof chip]"
              class="my-1 text-nowrap bg-grey-6 text-grey-2"
            >
              {{ (chip as object)[props.labelField as keyof typeof chip] }}
            </Chip>
          </div>
          <ComboboxInput
            :id="props.id"
            :display-value="() => String(value)"
            :label="label"
            :placeholder="selectedItem == null ? placeholder : ''"
            as="input"
            class="mr-10 flex w-full grow overflow-hidden outline-none"
            @keyup.delete="handleDelete"
            @click="handleSubmit"
            @input="handleInput"
          />
        </div>
      </div>
      <div v-else class="relative w-full">
        <ComboboxInput
          :id="props.id"
          v-model="value"
          :as="Input"
          :label="label"
          :placeholder="placeholder"
          class="w-full"
          @change="(e) => $emit('change', e)"
        >
        </ComboboxInput>
        <ComboboxButton
          class="absolute inset-y-0 right-0 mb-1 mr-1 flex w-8 items-center bg-white pl-1"
          :class="label ? 'top-8' : ''"
          :disabled="true"
        >
          <img :src="searchIcon" class="bg-white" />
        </ComboboxButton>
      </div>
      <ComboboxOptions
        :id="props.optionsId"
        class="absolute z-50 max-h-[10rem] w-full overflow-y-auto"
        @close="value = ''"
      >
        <ComboboxOption
          v-for="item in items"
          :id="'combobox-option-' + item.id"
          v-slot="{ active }"
          :key="item.id"
          :value="item"
          @click="handleOptionClick(item)"
        >
          <hr />
          <li
            :class="{
              'bg-green text-white': active,
              'bg-white text-black': !active,
            }"
            class="flex h-7 items-center overflow-hidden text-ellipsis text-nowrap pl-3"
          >
            {{ item[labelField as keyof typeof item] }}
          </li>
        </ComboboxOption>

        <ComboboxOption class="sticky bottom-0 w-full cursor-pointer">
          <slot></slot>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </HuiCombobox>
</template>
