<script setup lang="ts" generic="Button">
import { computed } from 'vue'
import { useAttrs } from 'vue'

const props = defineProps<{ loading?: boolean }>()

const attrs = useAttrs()

const classes = computed(() => {
  return typeof attrs?.class == 'string'
    ? attrs?.class
        ?.split(' ')
        .map((v) => '!' + v)
        .join(' ')
    : ''
})
</script>

<template>
  <button
    :id="String($attrs.id)"
    class="flex h-[2.4rem] min-w-[137px] items-center justify-center gap-2 whitespace-nowrap rounded-2xl bg-green px-6 py-1 font-bold text-white transition-all duration-100 disabled:border-[1px] disabled:border-grey-4 disabled:bg-grey-7 disabled:text-grey-4"
    :class="classes"
  >
    <Spinner v-if="props.loading" />
    <slot v-else />
  </button>
</template>
