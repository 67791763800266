import { FormError } from '@/types/error'
import { AxiosResponse } from 'axios'
import { ComposerTranslation } from 'vue-i18n'
import { useToast } from 'vue-toastification'

const toast = useToast()

const promiseHandler =
  (t: ComposerTranslation) =>
  async <T, E = FormError>(
    promise: Promise<{
      data?: T
      error?: E
      res?: AxiosResponse
    }>,
    handlers?: [
      (
        | ((data?: T, res?: AxiosResponse<T>) => unknown)
        | string
        | null
        | undefined
      ),
      (
        | ((error: E, res?: AxiosResponse<E>) => unknown)
        | string
        | null
        | undefined
      ),
    ]
  ) => {
    const defaultSuccessHandler = (text: string) => {
      toast.success(t(text))
    }
    const defaultErrorHandler = (text: string) => {
      toast.error(t(text))
    }

    const [onSuccess, onError] = handlers || []
    const { data, error, res } = await promise
    if (error) {
      if (!handlers || onError === null) return
      if (typeof onError === 'undefined')
        return defaultErrorHandler('general.messageProblemRequest')
      if (typeof onError === 'string') return defaultErrorHandler(onError)
      return onError(error, res)
    }

    if (!handlers || onSuccess === null) return
    if (typeof onSuccess === 'undefined')
      return defaultSuccessHandler('general.requestSuccess')
    if (typeof onSuccess === 'string') return defaultSuccessHandler(onSuccess)
    return onSuccess(data, res)
  }

export const usePromise = (t: ComposerTranslation) => promiseHandler(t)
