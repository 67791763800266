import { z } from 'zod'

export const addressFormSchema = z.object({
  id: z.number().optional(),
  label: z.string().optional(),
  line1: z.string().optional().nullish(),
  line2: z.string().optional().nullish(),
  line3: z.string().optional().nullish(),
  countryCode: z.string(),
  countryName: z.string().optional(),
  stateCode: z.string(),
  stateName: z.string().optional(),
  cityId: z.number(),
  cityName: z.string().optional(),
  postalCode: z.string().nullish(),
  main: z.boolean().optional(),
  deletionDate: z.string().nullish().optional(),
  daysToDelete: z.string().nullish().optional(),
  persons: z.array(z.string()).optional(),
})

export const countrySchema = z.object({
  countryCode: z.string(),
  name: z.string(),
  continent: z.string(),
  postalCodeFormat: z.string(),
  postalCodeRegex: z.string(),
  addressSize: z.number(),
})

export const stateSchema = z.object({
  stateCode: z.string(),
  name: z.string(),
})

export const citySchema = z.object({
  id: z.number(),
  name: z.string(),
})

export type Address = z.infer<typeof addressFormSchema>

export type Country = z.infer<typeof countrySchema> & { zodRule: z.ZodType }
export type State = z.infer<typeof stateSchema>
export type City = z.infer<typeof citySchema>

export type EditLabelHandlerParams = {
  id: number
  label: string
}
