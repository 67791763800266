<script lang="ts" setup>
import { useAddress } from '@/store/address'
import { onMounted, ref, watch } from 'vue'

import { useLoading } from '@/composables/useLoading'
import { usePostalCodeMask } from '@/composables/usePostalCodeMask'
import { Country, State } from '@/types/address'
import { toRefs } from 'vue'

const addressStore = useAddress()
const { cities, states, countries } = toRefs(addressStore)
const {
  getCountries,
  getCountryStates,
  getStateCities,
  loadingCities,
  loadingStates,
} = addressStore
const props = defineProps<{
  country?: string
  state?: string
  onUpdateCountry?: (newCountry: Country) => void
}>()

const { countryMask, maskOptions, postalCodeZodRule } = usePostalCodeMask()

const selectedCountry = ref<Country>()
const selectedState = ref<State | null>()

const getCountryByCode = (countryCode: string) =>
  countries?.value?.find((c) => c.countryCode === countryCode)

const getStateByCode = (stateCode: string) =>
  states?.value?.find((c) => c.stateCode === stateCode)

watch(
  () => props.country,
  () => {
    selectedCountry.value = getCountryByCode(props.country || '')
  }
)

watch(selectedCountry, async (country) => {
  selectedState.value = null

  if (!country) {
    states.value = []
    return
  }
  await getCountryStates(country.countryCode)
  selectedState.value = getStateByCode(props.state || '')

  if (props.onUpdateCountry) {
    props.onUpdateCountry(country)
  }
  countryMask.regex = country.postalCodeRegex
  countryMask.format = country.postalCodeFormat
})
watch(selectedState, (state) => {
  if (!state) {
    cities.value = []
    return
  }
  if (!loadingCities.value) getStateCities(state.stateCode)
})

function setSelectedCountry(newCountry: Country) {
  selectedCountry.value = getCountryByCode(String(newCountry))
}
function setSelectedState(newState: string) {
  selectedState.value = getStateByCode(newState)
}
watch(countries, async () => {
  if (!countries?.value?.length) return
  selectedCountry.value = getCountryByCode(props.country || '')

  if (selectedCountry.value && !loadingStates.value) {
    await getCountryStates(selectedCountry.value.countryCode)
    selectedState.value = getStateByCode(props.state || '')
  }

  if (selectedState.value && !loadingCities.value) {
    getStateCities(selectedState.value.stateCode)
  }
})
onMounted(() => {
  useLoading(async () => {
    await getCountries()
  })
})
</script>

<template>
  <slot
    :countries="countries"
    :cities="cities"
    :states="states"
    :selected-country="selectedCountry"
    :selected-state="selectedState"
    :set-selected-country="setSelectedCountry"
    :set-selected-state="setSelectedState"
    :on-update-country="onUpdateCountry"
    :mask-options="maskOptions"
    :postal-code-zod-rule="postalCodeZodRule"
  />
</template>
