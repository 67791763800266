import { z } from 'zod'

const customErrorMap: z.ZodErrorMap = (issue) => {
  if (issue.code === z.ZodIssueCode.invalid_union) return { message: '' }
  if (issue.code === z.ZodIssueCode.invalid_type) {
    return {
      message:
        issue.received === 'undefined'
          ? 'validation.required'
          : 'validation.invalidType',
    }
  }
  if (issue.code === z.ZodIssueCode.invalid_string) {
    if (issue.validation == 'email') {
      return { message: 'validation.email' }
    }
    return { message: `validation.invalidString` }
  }
  return { message: '' }
}
z.setErrorMap(customErrorMap)
