<script setup lang="ts">
import { computed, ref } from 'vue'
import {
  CircleStencil,
  Cropper,
  CropperResult,
  RectangleStencil,
} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

const props = defineProps<{
  open: boolean
  image?: string
  type?: string
}>()

const localImage = ref()

const emit = defineEmits(['close', 'save', 'update:dialog', 'update:image'])

const image = computed({
  get() {
    return props.image
  },
  set(value) {
    localImage.value = value
    emit('update:image', value)
  },
})

const resultRef = ref<CropperResult>()

function reset() {
  image.value = undefined
  emit('close')
}

function handleChange(result: CropperResult) {
  resultRef.value = result
}

function handleSave() {
  const dataUrl = resultRef.value?.canvas?.toDataURL('image/jpeg', 1.0)
  emit('save', dataUrl)
}
</script>

<template>
  <Dialog
    id="crop-image-dialog"
    :open="open"
    class="!w-[41rem] !max-w-xl"
    :title="$t('contacts.adjustProfilePicture')"
    @close="$emit('close')"
  >
    <div
      class="mx-2 flex !h-[40vh] w-full flex-col items-center justify-center overflow-hidden"
    >
      <Cropper
        :stencil-component="
          type === 'circle' ? CircleStencil : RectangleStencil
        "
        class="h-full w-full bg-white"
        :src="image"
        :stencil-props="{
          handlers: {},
          lines: false,
          movable: false,
          resizable: false,
          aspectRatio: 1,
        }"
        :stencil-size="{
          width: 200,
          height: 200,
        }"
        image-restriction="stencil"
        :canvas="{
          minHeight: 0,
          minWidth: 0,
          maxHeight: 200,
          maxWidth: 200,
        }"
        @change="handleChange"
      />
    </div>
    <div class="flex gap-2">
      <Button
        id="cancel-button"
        class="m-2 self-center bg-grey-3"
        @click="reset"
      >
        {{ $t('general.reset') }}
      </Button>
      <Button
        id="action-button"
        class="m-2 self-center bg-green"
        @click="handleSave"
      >
        {{ $t('general.continue') }}
      </Button>
    </div>
  </Dialog>
</template>
