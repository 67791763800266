import eveLogo from '@/assets/images/icon-eve-expert-big-square.png'
import { CorporateService } from '@/services/corporative'
import { Corporate, CorporateWithContactData } from '@/types/corporative'
import { defineStore } from 'pinia'
import { computed, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

const getCorporateLogo = (corporate: Corporate | CorporateWithContactData) =>
  corporate?.logo
    ? `${import.meta.env.VITE_API_URL}/resources/${corporate?.logo}`
    : eveLogo

export const useCorporativeAccount = defineStore('account', () => {
  const corporates = ref<Corporate[]>()
  const corporate = ref<Partial<CorporateWithContactData>>()
  const newCorporate = reactive<Partial<Corporate>>({})
  const selectedAccount = ref<Corporate>()
  const isUsingCorporateAccount = computed(() => Boolean(selectedAccount.value))
  const accountPermissions = computed(() => corporate.value?.permissions || [])
  const isAccountOwner = computed(() => corporate.value?.owner || false)
  const terms = ref<string>('')
  const loading = ref<boolean>(false)
  const toast = useToast()
  const { t } = useI18n()

  const route = useRoute()
  watch([route, corporates], () => {
    if (!corporates.value || !corporates.value.length) return
    if (route.params.id && corporate.value?.id != Number(route.params.id)) {
      getCorporate()
    }
    selectedAccount.value = corporates.value.find(
      (c) => c.id == Number(route.params.id)
    )
  })

  function saveNewCorporateData(payload: Partial<Corporate>) {
    Object.assign(newCorporate, payload)
  }
  async function getTerms() {
    const { data, error } = await CorporateService.getUsageTerms()
    if (error || !data) return
    terms.value = data.terms
  }
  async function createCorporativeAccount(payload: Partial<Corporate>) {
    const res = await CorporateService.create(payload)
    getCorporates()
    return res
  }

  async function getCorporate() {
    corporate.value = {}
    if (!route.params.id) return
    const { data, error } = await CorporateService.get(Number(route.params.id))
    if (error || !data) return
    corporate.value = {
      ...data,
      logo: getCorporateLogo(data),
    }
  }

  async function updateCorporativeAccount(account: CorporateWithContactData) {
    if (!account.id) throw new Error('missing account id')
    return await CorporateService.update(account)
  }

  async function updateCorporativeAccountLogo(logo: File) {
    return await CorporateService.updateCorporateLogo(
      Number(selectedAccount.value?.id),
      logo
    )
  }

  async function getCorporates() {
    const { data, error } = await CorporateService.list()

    if (error || !data) return
    corporates.value = data.map((c) => ({
      ...c,
      logo: getCorporateLogo(c),
    }))
  }

  function switchAccount(id?: number) {
    selectedAccount.value =
      corporates.value?.find((c) => c.id === id) || undefined
  }

  async function deleteCorporate(status: boolean) {
    if (!selectedAccount.value?.id) return
    return await CorporateService.delete(selectedAccount.value?.id, status)
  }

  async function uploadPrivacyPolicy(file: File) {
    if (!selectedAccount.value?.id) return
    const maxSizeInBytes = 10 * 1024 * 1024 // 10 MB
    if (file.size > maxSizeInBytes || file.type !== 'application/pdf') {
      toast.error(t('general.uploadError'))
    } else {
      toast.success(t('general.uploadSuccess'))
      return await CorporateService.uploadPrivacyPolicy(
        selectedAccount.value?.id,
        file
      )
    }
  }
  async function deletePrivacyPolicy() {
    if (!selectedAccount.value?.id) return
    toast.success(t('general.removeFile'))
    return await CorporateService.deletePrivacyPolicy(selectedAccount.value?.id)
  }

  async function updateOwner(idUser: string) {
    if (!selectedAccount.value?.id) return
    return await CorporateService.updateOwner(selectedAccount.value?.id, idUser)
  }

  async function requestAccess(id: number) {
    return await CorporateService.requestAccess(id)
  }

  async function removeLogoCompany(id: number) {
    return await CorporateService.removeLogoCompany(id)
  }

  return {
    corporate,
    selectedAccount,
    isUsingCorporateAccount,
    switchAccount,
    corporates,
    createCorporativeAccount,
    saveNewCorporateData,
    terms,
    getTerms,
    getCorporate,
    getCorporates,
    deleteCorporate,
    updateCorporativeAccountLogo,
    updateCorporativeAccount,
    uploadPrivacyPolicy,
    deletePrivacyPolicy,
    updateOwner,
    loading,
    requestAccess,
    accountPermissions,
    isAccountOwner,
    removeLogoCompany,
  }
})
