<script setup lang="ts">
import Header from '@/components/Header/Header.vue'
import Sidebar from '@/components/Sidebar/Sidebar.vue'
import { links } from '@/router/links'
import { computed } from 'vue'
import { RouterView } from 'vue-router'
import { useLayout } from '@/composables/useLayout'
import { useLoading } from '@/composables/useLoading'

const { globalLoading } = useLoading()
const { isSidebarOpen, toggleSidebar } = useLayout()
const sidebarStyle = computed(() => ({
  width: isSidebarOpen.value ? '50%' : '10%',
  maxWidth: isSidebarOpen.value ? '15rem' : '4rem',
}))
</script>

<template>
  <Header />
  <div
    class="relative flex w-full"
    :class="isSidebarOpen ? 'h-[92vh]' : 'h-[94vh]'"
  >
    <Sidebar
      :links="links"
      :style="sidebarStyle"
      :open="isSidebarOpen"
      @toggle="toggleSidebar"
    />
    <LoadingIcon v-show="globalLoading" />

    <RouterView
      v-if="!globalLoading"
      class="h-full w-full overflow-auto bg-grey-7"
    />
  </div>
</template>
