import { z } from 'zod'

export const corporateSchema = z.object({
  id: z.number(),
  about: z.string().optional().nullable(),
  name: z.string().optional(),
  tradingName: z.string(),
  document: z.string(),
  logo: z.string().or(z.instanceof(File).nullish()),
  privacyPolicy: z.string().optional().nullable(),
  deleted: z.boolean().optional().nullable(),
  deletionDate: z.string().optional().nullable(),
  corporateEmail: z.string().optional().nullable(),
  owner: z.boolean(),
  daysToDelete: z.number().optional().nullable(),
  permissions: z.array(z.string()).optional(),
})

export const editCorporateFormSchema = z
  .object({
    id: z.number(),
    name: z.string().optional(),
    tradingName: z.string(),
    document: z.string(),
    about: z.string().optional(),
    logo: z.string().or(z.instanceof(File).nullish()),
  })
  .merge(
    z.object({
      addresses: z.array(z.unknown()).optional(),
      emails: z.array(z.unknown()).optional(),
      phones: z.array(z.unknown()).optional(),
    })
  )

export const corporateWithContactDataSchema = corporateSchema
  .merge(
    z.object({
      addresses: z.array(z.unknown()).optional(),
      emails: z.array(z.unknown()).optional(),
      phones: z.array(z.unknown()).optional(),
    })
  )
  .merge(
    z.object({
      ownerMember: z
        .object({
          userId: z.string().optional(),
          name: z.string().optional(),
          firstName: z.string().optional(),
          lastName: z.string().optional(),
          corporateEmail: z.string().email(),
          permissionPresetId: z.number().optional(),
          permissionPresetName: z.string().optional(),
        })

        .optional(),
    })
  )

export const corporateDetailFormSchema = corporateSchema.and(
  z.object({
    permissions: z.string(),
  })
)

export type Corporate = z.infer<typeof corporateSchema>

export type CorporateWithContactDataPayload = z.infer<
  typeof corporateDetailFormSchema
>

export type CorporateWithContactData = z.infer<
  typeof corporateWithContactDataSchema
>

export type CorporateEditWithContactData = z.infer<
  typeof editCorporateFormSchema
>
