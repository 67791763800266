export interface Item {
  target?: string
  name: string
  children?: Array<Item>
}

export const links = [
  {
    name: 'production',
    target: '',
    children: [
      { name: 'settings' },
      { name: 'schedule' },
      { name: 'stock' },
      { name: 'calculators' },
      { name: 'team' },
      { name: 'attractions' },
      { name: 'satisfactionSearch' },
      {
        name: 'polls',
        children: [{ name: 'quiz' }, { name: 'award' }],
      },
      {
        name: 'settings',
      },
    ],
  },
  {
    name: 'schedule',
    children: [
      { name: 'agenda' },
      { name: 'activities' },
      { name: 'settings' },
    ],
  },
  {
    name: 'participants',
    children: [
      { name: 'subscriptions' },
      { name: 'participationStatus' },
      {
        name: 'attendanceControl',
        children: [
          { name: 'event' },
          { name: 'transport' },
          { name: 'activities' },
        ],
      },
      { name: 'groups' },
      { name: 'transport' },
      { name: 'accommodationRoaming' },
      { name: 'credential' },
      {
        name: 'participantsRelations',
        children: [{ name: 'settings' }, { name: 'chat' }],
      },
    ],
  },
  {
    name: 'communication',
    children: [
      {
        name: 'hotsite',
        children: [
          { name: 'layout' },
          { name: 'pages' },
          { name: 'publishings' },
          { name: 'banners' },
        ],
      },
      {
        name: 'newsletter',
        children: [
          { name: 'newNewsletter' },
          { name: 'scheduledAndDrafts' },
          { name: 'sentNewsletters' },
        ],
      },
      {
        name: 'emailMarketing',
        children: [
          { name: 'newEmailMarketing' },
          { name: 'scheduledAndDrafts' },
        ],
      },
      {
        name: 'notifications',
        children: [
          { name: 'newNotification' },
          { name: 'scheduledAndDrafts' },
          { name: 'sentNotifications' },
        ],
      },
      {
        name: 'socialMedia',
      },
    ],
  },
  {
    name: 'financial',
    children: [
      { name: 'budget' },
      { name: 'quotes' },
      { name: 'payments' },
      {
        name: 'sponsorship',
        children: [{ name: 'quotes' }, { name: 'sellings' }],
      },
    ],
  },
]
