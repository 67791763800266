import Toast, { PluginOptions } from 'vue-toastification'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'
import WarningIcon from '@/components/WarningIcon.vue'

export const vueToastificationOptions: PluginOptions = {
  icon: WarningIcon,
}

export const ToastPlugin = Toast
