<script setup lang="ts"></script>
<template>
  <div class="flex h-full items-center justify-center">
    <div class="gradiente h-[691px] w-[880px] items-center justify-center">
      <div class="h-32 w-full pl-6 pt-6 text-left">
        <img
          src="/src/assets/images/logo-eve-white.png"
          alt="Logo"
          class="imgLogoEveWhite"
        />
      </div>
      <div class="flex h-32 w-full items-center justify-center">
        <img
          src="/src/assets/images/sad-cloud.png"
          alt="Logo"
          width="120"
          height="75.63"
        />
      </div>
      <div class="flex h-32 w-5/12 items-center justify-center">
        <p class="text-3xl font-semibold leading-10">
          {{ $t('general.keycloackErrorTitle') }}
        </p>
      </div>
      <div class="flex h-64 w-[400px] justify-center">
        <p class="mt-0 text-xs font-semibold leading-4">
          {{ $t('general.keycloackErrorDetail') }}
        </p>
        <img
          src="/src/assets/images/bg-logo.png"
          alt="Logo"
          width="399"
          height="454"
          class="imgLogoEve"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.gradiente {
  border-radius: 20px;
  background: linear-gradient(
    168.66616466754974deg,
    #a444bd 0%,
    #84319a 38.25%,
    #531364 74.58%,
    #2a0334 100%
  );
  color: white;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imgLogoEve {
  position: fixed;
  top: 420px;
  left: 400px;
  width: 435px;
  height: 435px;
  opacity: 0.5;
  z-index: 0;
}

.imgLogoEveWhite {
  top: 40px;
  left: 40px;
  width: 103px;
  height: 35px;
  z-index: 0;
}
</style>
