import { z } from 'zod'
export const emailFormSchema = z.object({
  main: z.boolean().optional(),
  label: z.string().optional(),
  email: z.string().email(),
  id: z.number().optional(),
  persons: z.array(z.string()).optional(),
})

export type Email = z.infer<typeof emailFormSchema>
