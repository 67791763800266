<script setup lang="ts">
import UserMenu from './UserMenu.vue'
import TopNav from './TopNav.vue'

import eventLogo from '@/assets/images/glyph-gray.png'
import eveIcon from '@/assets/images/icon-eve-expert-big-square.png'
import { useLayout } from '@/composables/useLayout'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useCorporativeAccount } from '@/store/corporative'

const { isSidebarOpen } = useLayout()

const dynamicClasses = computed(() =>
  isSidebarOpen.value
    ? { header: 'h-[8vh]', images: 'w-[3rem] ml-2', tile: 'w-[0.3rem]' }
    : { header: 'h-[6vh]', images: 'w-[2.2rem] ml-2', tile: 'w-[4rem]' }
)

const accountStore = useCorporativeAccount()
const { selectedAccount, isUsingCorporateAccount } = storeToRefs(accountStore)
</script>

<template>
  <header
    class="relative flex w-full items-center justify-between transition-all duration-100"
    :class="`${dynamicClasses.header} ${
      isUsingCorporateAccount ? 'bg-corporate' : 'bg-personal '
    }`"
  >
    <div class="h-full flex-grow">
      <div
        id="logo-container"
        class="relative flex h-full w-full items-center justify-start px-2 transition duration-100"
      >
        <div
          class="absolute left-0 top-0 min-h-full bg-white transition-all duration-500 ease-out"
          :class="dynamicClasses.tile"
        >
          &nbsp;
        </div>
        <RouterLink to="/" class="flex h-full items-center">
          <img
            :src="eventLogo"
            alt="Logotipo do evento"
            class="absolute left-2 z-20 rounded-full object-cover transition-all"
            :class="dynamicClasses.images"
          />
        </RouterLink>
      </div>
    </div>
    <TopNav />
    <UserMenu />
    <button
      type="button"
      class="mr-4 h-auto transition duration-100"
      :class="dynamicClasses.images"
    >
      <img
        :src="selectedAccount?.logo ? String(selectedAccount?.logo) : eveIcon"
        class="rounded-2xl object-cover"
        alt=""
      />
    </button>
  </header>
</template>
