import Create from '@/views/Create.vue'
import Detail from '@/views/Detail.vue'
import Edit from '@/views/Edit.vue'
import Home from '@/views/Panel/PanelIndex.vue'
import Onboarding from '@/views/Onboarding.vue'
import { RouteRecordRaw } from 'vue-router'

export const accountRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('../layouts/Main.vue'),
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/Profile.vue'),
      },
      {
        path: 'create',
        name: 'create-corporative-account',
        component: Create,
      },
      {
        path: ':id',
        name: 'home-corporative-account',
        component: Home,
      },
      {
        path: ':id/detail',
        name: 'detail-corporative-account',
        component: Detail,
      },
      {
        path: ':id/edit',
        name: 'edit-corporative-account',
        component: Edit,
        meta: { isAdmin: true },
      },
      {
        path: '/',
        name: 'home-event',
        component: Home,
      },
      { path: 'onboarding', name: 'onboarding', component: Onboarding },
    ],
  },
]
