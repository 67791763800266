<script setup lang="ts">
import editIcon from '@/assets/icons/black/edit.svg'
import emailIcon from '@/assets/icons/green/e-mail.svg'
import locationIcon from '@/assets/icons/green/location.svg'
import newTabTermIcon from '@/assets/icons/green/newTab.svg'
import phoneIcon from '@/assets/icons/green/phone.svg'
import walletIcon from '@/assets/icons/green/wallet.svg'
import uploadIconGrey from '@/assets/icons/grey/upload.svg'
import deleteTermIcon from '@/assets/icons/red/delete.svg'
import alertIcon from '@/assets/icons/white/attention.svg'
import transferPropertyIcon from '@/assets/icons/white/contactscheckbox.svg'
import warningIcon from '@/assets/icons/white/icon-warning.png'
import uploadIconWhite from '@/assets/icons/white/upload.svg'
import logoPlaceholder from '@/assets/images/logo-placeholder.png'
import AlertDialog from '@/components/AlertDialog.vue'
import Card from '@/components/Card.vue'
import Dialog from '@/components/Dialog.vue'
import { useLoading } from '@/composables/useLoading'
import { useMember } from '@/modules/member/store'
import { MessageSchema } from '@/plugins/i18n'
import { useCorporativeAccount } from '@/store/corporative'
import { Corporate } from '@/types/corporative'
import { convertDateStringToDMY } from '@/utils/dataStringToDMY'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'
import ChangeOwnershipDialog from '../components/ChangeOwnershipDialog.vue'
import DetailDataSection from '../components/DetailDataSection.vue'
import { initialsPicture } from '@/utils/getInitialsPicture'

const route = useRoute()

const toast = useToast()
const { t } = useI18n<MessageSchema>()

const corporateStore = useCorporativeAccount()
const memberStore = useMember()
const {
  getCorporate,
  deleteCorporate,
  uploadPrivacyPolicy,
  deletePrivacyPolicy,
  updateOwner,
} = corporateStore
const { corporate } = storeToRefs(corporateStore)

const { getMembers } = memberStore
const { members } = storeToRefs(memberStore)

const corporatePolicy = computed(
  () =>
    `${import.meta.env.VITE_API_URL}/resources/${
      corporate.value?.privacyPolicy
    }`
)

const sections: {
  title: string
  icon: string
  field: 'addresses' | 'emails' | 'phones' | 'about'
  emptyState: string
}[] = [
  {
    title: 'corporate.detail.about',
    field: 'about',
    icon: walletIcon,
    emptyState: 'corporate.detail.emptyStateAbout',
  },
  {
    title: 'corporate.detail.phones',
    field: 'phones',
    icon: phoneIcon,
    emptyState: 'corporate.detail.emptyStatePhones',
  },
  {
    title: 'corporate.detail.emails',
    field: 'emails',
    icon: emailIcon,
    emptyState: 'corporate.detail.emptyStateEmails',
  },
  {
    title: 'corporate.detail.addresses',
    field: 'addresses',
    icon: locationIcon,
    emptyState: 'corporate.detail.emptyStateAddresses',
  },
]

const isOpen = ref(false)
const isChangeOwnershipDialogOpen = ref(false)
const isOpenForbiddenDeletion = ref(false)
const isOpenError = ref(false)
let selectedFile: File | null = null

const profilePicture = computed(() => {
  const owner = corporate.value?.ownerMember
  return initialsPicture(String(owner?.name))
})

function openModal() {
  isOpen.value = true
}

function closeModal() {
  isOpen.value = false
}

function closeModalError() {
  isOpenError.value = false
}

function handleFileChange(event: Event) {
  const input = event.target as HTMLInputElement
  if (input.files && input.files.length > 0) {
    selectedFile = input.files[0]
    attachmentFileTerm()
    selectedFile = null
  }
  input.value = ''
}

async function attachmentFileTerm() {
  if (!selectedFile) {
    toast.success(t('general.noFileSelectedUpload'))
    return
  }
  try {
    await uploadPrivacyPolicy(selectedFile)
    getCorporate()
  } catch (error) {
    console.log(error)
  }
}

async function closeChangeOwnershipDialog(userId: string) {
  isChangeOwnershipDialogOpen.value = false
  if (!userId) return

  await updateOwner(userId)
  getCorporate()
}

function openChangeOwnershipDialog() {
  isChangeOwnershipDialogOpen.value = true
}

async function showCardDeleteACcount() {
  isOpen.value = false
  try {
    await deleteCorporate(false)
    getCorporate()
  } catch (error) {
    console.log(error)
  }
}

async function unDeleteAccount() {
  try {
    await deleteCorporate(true)
    getCorporate()
  } catch (error) {
    console.log(error)
  }
}

function openFileInput(): void {
  const inputFile = document.getElementById('inputFileTerms')
  if (inputFile) {
    inputFile.click()
  }
}

async function deleteFileFileTerm() {
  try {
    await deletePrivacyPolicy()
    getCorporate()
  } catch (error) {
    console.log(error)
  }
}

/*
******************************************************************************************
"To be called when the events module is implemented. If deletion is requested and there are
scheduled events, this modal should appear.
******************************************************************************************
async function openModalForbiddenDeletion() {
  isOpenForbiddenDeletion.value = true
}
*/

async function closeModalForbiddenDeletion() {
  isOpenForbiddenDeletion.value = false
}

watch(
  () => route.params.id,
  () =>
    useLoading(
      async () => {
        await getCorporate()
      },
      { global: true }
    )
)
function handleMembersSearch(searchTerm: string) {
  getMembers({ id: route.params.id as string, searchTerm })
}

watch(route, (newRoute) => {
  const id = newRoute.params.id
  fetchData()
  getMembers({ id: id as string, searchTerm: '' })
})

async function fetchData() {
  useLoading(async () => {
    await getCorporate()
  })
}

onMounted(() => {
  fetchData()
})
</script>
<template>
  <Page>
    <div class="h-full overflow-auto px-[15vw] py-10 text-grey-2">
      <AlertDialog
        class="relative z-10 flex items-center justify-center"
        :is-open="isOpen"
        :icon="alertIcon"
        color="error"
        :text="$t('corporate.account.messageModalConfirmDeletion')"
        :title="$t('general.advise')"
        @close="closeModal()"
      >
        <div class="flex gap-2">
          <Button
            class="!min-w-[10rem] border-2 bg-grey-3"
            @click="closeModal"
            >{{ $t('general.cancel') }}</Button
          >
          <Button
            :class="`min-w-[10rem] border-2 bg-error`"
            @click="showCardDeleteACcount"
            >{{ $t('general.ok') }}</Button
          >
        </div>
      </AlertDialog>
      <AlertDialog
        :is-open="isOpenForbiddenDeletion"
        :icon="alertIcon"
        color="alert"
        :text="$t('corporate.account.messageDeletionDenied')"
        :title="$t('general.advise')"
        @close="closeModalForbiddenDeletion"
      >
        <div class="flex gap-2">
          <Button
            class="!min-w-[10rem] border-2 bg-alert"
            @click="closeModalForbiddenDeletion"
            >{{ $t('general.ok') }}
          </Button>
        </div>
      </AlertDialog>
      <Dialog
        :open="isOpenError"
        color="alert"
        :text="$t('general.messageProblemRequest')"
        :title="$t('general.advise')"
        @close="closeModal()"
      >
        <Button
          class="!min-w-[10rem] border-2 bg-grey-3"
          @click="closeModalError"
          >{{ $t('general.ok') }}
        </Button>
      </Dialog>

      <div class="flex w-full gap-4 rounded-xl bg-white p-5">
        <img
          :src="corporate?.logo ? String(corporate?.logo) : logoPlaceholder"
          alt=""
          class="h-[10rem] w-[10rem] rounded-xl object-cover"
        />

        <div class="flex max-w-[80%] flex-col gap-2 text-base">
          <h4 class="dash truncate !text-xl font-bold text-green">
            {{ corporate?.tradingName }}
          </h4>
          <div>
            <p class="font-light">{{ $t('corporate.detail.name') }}</p>
            <p class="truncate font-bold">{{ corporate?.name }}</p>
          </div>
          <div>
            <p class="font-light">{{ $t('corporate.detail.document') }}</p>
            <p class="font-bold">{{ corporate?.document }}</p>
          </div>
        </div>
      </div>
      <div class="mt-10 grid grid-cols-2 gap-4">
        <div class="col-span-1">
          <div class="mb-10 flex items-center justify-between">
            <h3 class="dash text-2xl font-bold">
              {{ $t('corporate.detail.corporateData') }}
            </h3>
            <RouterLink
              v-if="corporate?.owner"
              id="edit-corporative-account-link"
              :to="{
                name: 'edit-corporative-account',
                params: { id: $route.params.id },
              }"
            >
              <img :src="editIcon" alt="edit-icon" />
            </RouterLink>
          </div>

          <DetailDataSection
            v-for="section in sections"
            :key="section.field"
            :data="section"
            :corporative-account="corporate as Corporate"
          />
        </div>
        <div class="col-span-1">
          <div class="flex flex-col">
            <h3 class="dash mb-4 text-2xl font-bold">
              {{ $t('corporate.detail.owner') }}
            </h3>

            <div v-if="corporate?.owner" class="flex flex-col">
              <p class="mb-4 px-2 text-left text-base">
                {{ $t('corporate.detail.messageOwner') }}
              </p>

              <Button class="self-center" @click="openChangeOwnershipDialog">
                <img :src="transferPropertyIcon" alt="" />
                {{ $t('corporate.detail.transferOwnership') }}
              </Button>
            </div>

            <div v-else class="flex flex-row">
              <img
                :src="profilePicture"
                class="mx-4 my-2.5 h-11 w-11 rounded-full"
              />
              <span class="flex flex-col">
                <h4 class="pt-3.5 text-sm font-bold">
                  {{ corporate?.ownerMember?.name }}
                </h4>
                <h5 class="text-xs text-grey-0">
                  {{ corporate?.name }}
                </h5>
              </span>
            </div>

            <ChangeOwnershipDialog
              :members="members"
              :is-open-owner="isChangeOwnershipDialogOpen"
              @close="closeChangeOwnershipDialog"
              @search="handleMembersSearch"
            />
          </div>
          <div v-if="corporate?.owner" class="my-4 flex flex-col">
            <h3 class="dash my-4 text-2xl font-bold">
              {{ $t('corporate.detail.privacyAndDataPolicy') }}
            </h3>

            <p class="mb-4 px-2 text-left text-base">
              {{ $t('corporate.detail.explanationAboutPrivacyAndDataPolicy') }}
            </p>
            <div
              v-show="corporate?.privacyPolicy !== null"
              class="w-100 mb-3 flex h-14 items-center p-4 backdrop-brightness-200"
            >
              <div class="w-80 font-thin">
                {{ $t('corporate.detail.attachedTerms') }}
              </div>

              <div class="w-10">
                <a :href="corporatePolicy" target="_blank">
                  <img :src="newTabTermIcon" alt="" />
                </a>
              </div>
              <div class="w-10">
                <img :src="deleteTermIcon" alt="" @click="deleteFileFileTerm" />
              </div>
            </div>
            <Button
              class="self-center"
              :disabled="corporate?.privacyPolicy !== null"
              @click="openFileInput"
            >
              <img
                :src="
                  corporate?.privacyPolicy ? uploadIconGrey : uploadIconWhite
                "
                alt=""
              />{{ $t('corporate.detail.appendTerms') }}</Button
            >
            <input
              id="inputFileTerms"
              type="file"
              name="inputFileTerms"
              accept="application/pdf"
              class="hidden"
              @change="handleFileChange"
            />
            <p class="my-1 text-center text-xs">
              {{ $t('corporate.detail.footerTextPrivacyAndDAtPolicy') }}
            </p>
          </div>

          <div
            v-if="corporate?.deleted && corporate.owner"
            class="flex flex-col"
          >
            <h3 class="dash my-4 text-2xl font-bold">
              {{ $t('corporate.detail.deleteCorporate') }}
            </h3>
            <Card color="error" :icon="warningIcon" class="mt-4">
              <h3 class="py-2 text-center font-bold">
                {{ $t('general.attention') }}
              </h3>
              <p class="px-8 pb-4 text-center">
                {{
                  $t('corporate.account.messageAccountDeletion', {
                    formattedDeletionDate: convertDateStringToDMY(
                      corporate?.deletionDate as string
                    ),
                    daysLeft: corporate?.daysToDelete,
                  })
                }}
              </p>
              <div class="flex items-center justify-center">
                <Button
                  class="border-2 border-error !bg-error"
                  @click="unDeleteAccount"
                  >{{ $t('general.cancelDeletion') }}</Button
                >
              </div>
            </Card>
          </div>
        </div>
      </div>
      <div class="mt-4 flex justify-center">
        <Button
          v-if="!corporate?.deleted && corporate?.owner"
          class="border-2 border-error bg-transparent !text-error"
          @click="openModal"
          >{{ $t('corporate.detail.deleteCorporate') }}</Button
        >
      </div>
    </div>
  </Page>
</template>
