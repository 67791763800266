<script setup lang="ts">
import wallet from '@/assets/icons/black/company.svg'
import editIcon from '@/assets/icons/black/edit.svg'
import checkOutRedIcon from '@/assets/icons/check_out_red.svg'
import { MenuItem, MenuItems } from '@headlessui/vue'
import { useI18n } from 'vue-i18n'
import CardOptions from './CardOptions.vue'

const { t } = useI18n()

const { id } = defineProps<{ id: number; isOwner: boolean }>()
</script>

<template>
  <CardOptions :id="`corporative-account-menu`">
    <MenuItems
      as="div"
      class="absolute right-0 top-0 z-10 mt-0 w-72 origin-top-left divide-y rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    >
      <Card class="!flex !w-full !items-start !p-0" color="green">
        <div class="mt-1 flex w-full flex-col md:flex-row">
          <div class="w-10% mt-2 flex-auto items-center">
            <img :src="wallet" alt="" class="mr-2 h-10 w-10" />
          </div>
          <div class="m-2 w-full flex-auto items-center pt-2">
            <RouterLink
              :to="{ name: 'detail-corporative-account', params: { id } }"
              class="flex items-center"
            >
              <span class="mt-0 font-bold">{{
                t('profile.corporates.goToCorporativeAccount')
              }}</span>
            </RouterLink>
          </div>
        </div>
        <div v-if="$props.isOwner" class="mx-2 my-1 flex w-64 items-center">
          <div class="flex-grow border-b border-t border-gray-300"></div>
        </div>
        <div
          v-if="$props.isOwner"
          class="mb-2 mt-0 flex w-full flex-col md:flex-row"
        >
          <div class="w-10% ml-2 mt-2 flex-auto items-center">
            <img :src="editIcon" alt="" class="mr-2" />
          </div>
          <div class="ml-2 mt-2 w-full flex-auto items-center pt-0">
            <RouterLink
              :to="{ name: 'edit-corporative-account', params: { id: id } }"
              class="flex items-center font-bold"
            >
              <span class="mt-0">{{
                t('profile.corporates.editCorporativeAccount')
              }}</span>
            </RouterLink>
          </div>
        </div>
        <div class="mx-2 my-1 flex w-64 items-center">
          <div class="flex-grow border-b border-t border-gray-300"></div>
        </div>
        <div class="mb-2 mt-1 flex w-full flex-col md:flex-row">
          <div class="w-10% ml-2 mt-2 flex-auto items-center">
            <img :src="checkOutRedIcon" alt="" class="mr-2" />
          </div>
          <div class="m-2 w-full flex-auto items-center pt-0">
            <MenuItem as="div" class="w-full !text-left text-error">
              <div class="flex font-bold">
                <span>{{ t('profile.corporates.exit') }}</span>
              </div>
            </MenuItem>
          </div>
        </div>
      </Card>
    </MenuItems>
  </CardOptions>
</template>
