<script setup lang="ts">
import notificationsIcon from '@/assets/icons/white/notificacao_in_off.svg'
import eveIcon from '@/assets/images/icon-eve-expert-big-square.png'
import Dialog from '@/components/Dialog.vue'
import { useMember } from '@/modules/member/store'
import useStore from '@/store/auth'
import { useCorporativeAccount } from '@/store/corporative'
import { getCookie } from '@/utils/cookies'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

const authStore = useStore()

const { getUserInfo } = authStore
const { user } = storeToRefs(authStore)
const cookie = ref()
const accountPicture = ref()
const selectedStep = ref<number>(0)

const { acceptInvite } = useMember()
const accountStore = useCorporativeAccount()
const { getCorporates, switchAccount } = accountStore
const { corporates } = storeToRefs(accountStore)

const toast = useToast()
const { t } = useI18n()
const steps = [{ text: 'onboarding.success' }, { text: 'onboarding.tip' }]
const isDialogOpen = ref(false)
const router = useRouter()

function setupOnboarding() {
  getUserInfo().then(async () => {
    cookie.value = getCookie('COMPANY_INVITE_KEY')

    if (!cookie.value) {
      toast.error(t('onboarding.cookieError'))
      return
    }
    const { data, error } = await acceptInvite(cookie.value)
    if (error) {
      if (error.detail) toast.error(error.detail)
      else toast.error(t('onboarding.acceptError'))

      router.push({ name: 'home' })
      return
    }
    accountPicture.value = `${
      import.meta.env.VITE_API_URL
    }/resources/${data?.logo}`

    await getCorporates()
    const accountToSelect = corporates.value?.find(
      (c) => c.tradingName === data?.tradingName
    )
    if (!accountToSelect) return toast.error(t('onboarding.accountError'))
    switchAccount(accountToSelect.id)
    isDialogOpen.value = true
  })
}

onMounted(setupOnboarding)
</script>

<template>
  <Dialog
    :open="isDialogOpen"
    :title="$t('onboarding.title')"
    @close="isDialogOpen = false"
  >
    <div
      class="gap-4bg-grey-5 my-4 flex w-full flex-col items-center justify-center"
    >
      <div
        v-if="selectedStep == 1"
        class="relative flex h-[6rem] w-[6rem] items-center gap-2 overflow-hidden rounded-full bg-personal"
      >
        <div class="absolute -left-2 flex items-center gap-2">
          <img
            :src="notificationsIcon"
            class="-left-2 top-7 h-[1.5rem] w-[1.5rem]"
          />
          <img
            :src="user?.profilePicture"
            class="border- h-[3rem] w-[3rem] rounded-full border-4 border-green p-1"
          />

          <img
            :src="eveIcon"
            class="-right-6 top-5 h-[2.5rem] w-[2.5rem] rounded-md"
          />
        </div>
      </div>
      <div class="flex items-center justify-center text-center">
        {{ $t(steps[selectedStep].text) }}
      </div>
    </div>

    <template #actions>
      <div
        class="flex h-full w-full flex-col items-center gap-4 border-t-[2px] border-grey-5 bg-white py-4"
      >
        <div class="flex justify-center">
          <div
            class="flex cursor-pointer items-center justify-center place-self-center rounded-full bg-green"
            :class="
              selectedStep == 0
                ? 'h-[1rem] w-[1rem] bg-green'
                : 'h-[0.5rem] w-[0.5rem] bg-grey-4'
            "
            @click="selectedStep = 0"
          />
          <div class="h-[0.1rem] w-[3rem] place-self-center bg-grey-4"></div>
          <div
            class="flex cursor-pointer items-center justify-center place-self-center rounded-full bg-green"
            :class="
              selectedStep == 1
                ? 'h-[1rem] w-[1rem] bg-green'
                : 'h-[0.5rem] w-[0.5rem] bg-grey-4'
            "
            @click="selectedStep = 1"
          />
        </div>
        <Button
          @click="
            selectedStep == 0 ? (selectedStep = 1) : (isDialogOpen = false)
          "
        >
          {{ selectedStep == 0 ? $t('general.continue') : $t('general.ok') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>
