import KeycloakService from '@/security/KeycloakService'
import { KeycloakTokenParsed } from 'keycloak-js'
import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'
import { UsersService } from '@/services/user'

const useStore = defineStore('auth', () => {
  const user: Ref<KeycloakTokenParsed | undefined> = ref()
  const token: Ref<string | undefined> = ref()

  const setUser = (u?: KeycloakTokenParsed) => {
    user.value = u
  }
  const setToken = (t: string | undefined) => {
    token.value = t
  }

  async function logout() {
    await KeycloakService.Logout()
    user.value = undefined
  }

  async function getUserInfo() {
    const { data, error } = await UsersService.get()
    if (error || !data) return
    const fullName = `${data.firstName} ${data.lastName}`
    const profilePicture = `https://ui-avatars.com/api/?name=${data.firstName}+${data.lastName}&background=random&size=512`

    user.value = {
      ...data,
      fullName,
      profilePicture,
    }

    return data
  }

  return { user, token, setUser, setToken, logout, getUserInfo }
})

export default useStore
