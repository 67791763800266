<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import orderIconBlack from '@/assets/icons/black/order.svg'
import orderIconDown from '@/assets/icons/green/order_down.svg'
import orderIconUp from '@/assets/icons/green/order_up.svg'
import { DefalultOrder } from '@/services/types'
import { computed, ref } from 'vue'

const props = defineProps<{
  order?: string
  orderBy?: string
  multiple?: boolean
  orderingOptions: { value: string; label: string }[]
  defaultOrder: DefalultOrder
}>()

const orderBy = computed(
  () =>
    props.orderingOptions?.find((option) => option.value == props.orderBy)
      ?.label || null
)

const localOrder = ref(props.order)
const secondaryLocalOrder = ref(props.order)
const localOrderBy = ref(orderBy.value)
const secondaryLocalOrderBy = ref(orderBy.value)

const filterOrderIcon = computed(() => {
  if (props.order !== props.defaultOrder.ORDER) {
    return props.defaultOrder.ORDER === 'desc' ? orderIconDown : orderIconUp
  }

  return orderIconBlack
})

const emit = defineEmits([
  'order-by',
  'order',
  'clear-ordering',
  'handle-order',
  'close',
])

function getValueByLabel(label: string) {
  const item = props.orderingOptions.find((entry) => entry.label === label)
  return item ? item.value : null
}

function handleOrderAndCloseMenu(closeMenu: () => void) {
  const primaryValue = getValueByLabel(localOrderBy.value as string)
  if (!primaryValue) {
    console.error(`Label ${localOrderBy.value} não encontrado.`)
    return
  }
  const secondaryValue = secondaryLocalOrderBy.value
    ? getValueByLabel(secondaryLocalOrderBy.value as string)
    : null
  emit(
    'order-by',
    props.multiple ? `${primaryValue},${secondaryValue}` : primaryValue
  )
  emit(
    'order',
    secondaryLocalOrder.value
      ? `${localOrder.value},${secondaryLocalOrder.value}`
      : ''
  )
  closeMenu()
}
</script>
<template>
  <Menu as="div" class="relative inline-block text-left">
    <MenuButton
      id="order-menu"
      :disabled="!orderingOptions?.length"
      class="flex w-[2rem]"
      :class="{ 'opacity-30': !orderingOptions?.length }"
      as="button"
    >
      <img :src="filterOrderIcon" width="25" class="mr-2" />
    </MenuButton>
    <MenuItems
      :class="props.multiple ? 'h-[25rem]' : 'h-40'"
      class="absolute right-0 z-10 mt-2 w-[28rem] origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    >
      <MenuItem v-slot="{ close }">
        <div
          :class="props.multiple ? 'p-5' : 'p-2'"
          class="flex h-full w-full flex-col"
        >
          <h3 class="py-3 font-bold">
            {{ $t('corporate.order.modalLabel') }}
          </h3>

          <OrderListbox
            v-model="localOrderBy"
            :order="localOrder"
            :ordering-options="props.orderingOptions"
            :multiple="props.multiple"
            @update-order="localOrder = $event"
          />
          <OrderListbox
            v-if="props.multiple"
            v-model="secondaryLocalOrderBy"
            :order="secondaryLocalOrder"
            :ordering-options="props.orderingOptions"
            :multiple="props.multiple"
            is-secondary="true"
            @update-order="secondaryLocalOrder = $event"
          />

          <div
            :class="props.multiple ? 'my-4 justify-end' : 'justify-center py-4'"
            class="flex flex-row gap-2"
          >
            <Button
              class="!important min-w-[9rem] bg-grey-3"
              @click="$emit('clear-ordering')"
            >
              {{ $t('corporate.order.clear') }}
            </Button>
            <Button
              id="save-order-button"
              class="!important min-w-[9rem]"
              @click="handleOrderAndCloseMenu(close)"
            >
              {{ $t('corporate.order.order') }}
            </Button>
          </div>
        </div>
      </MenuItem>
    </MenuItems>
  </Menu>
</template>
