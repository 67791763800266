import 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { memberRoutes } from '@/modules/member/routes'
import { accountRoutes } from '@/router/routes'
import { contactsRoutes } from '@/modules/contacts/routes'
import { globalRoutes } from './global'
import { customFieldsRoutes } from '@/modules/custom-fields/routes'

declare module 'vue-router' {
  interface RouteMeta {
    isAdmin?: boolean
    requiredPermission?: string | string[]
  }
}

export const router = createRouter({
  history: createWebHistory(), // mudar dps process.env.BASE_URL
  routes: [
    ...globalRoutes,
    ...accountRoutes,
    ...memberRoutes,
    ...contactsRoutes,
    ...customFieldsRoutes,
  ],
})
