import { Ref, ref } from 'vue'

const isSidebarOpen: Ref<boolean> = ref(false)

function toggleSidebar() {
  isSidebarOpen.value = !isSidebarOpen.value
}
export const useLayout = () => {
  return { isSidebarOpen, toggleSidebar }
}
