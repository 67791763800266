<script setup lang="ts" generic="T">
import { computed, toRefs, useAttrs } from 'vue'

defineOptions({
  inheritAttrs: false,
})
const props = withDefaults(
  defineProps<{
    label: string
    required?: boolean
    items: T[] | null
    modelValue?: string | number | undefined
    labelField?: keyof T | string
    valueField?: keyof T | string
    loading?: boolean
    error?: boolean
    emptyState?: boolean
    placeholder?: string
    disabled?: boolean
  }>(),
  {
    required: false,
    labelField: 'label',
    valueField: 'value',
    modelValue: undefined,
    loading: false,
    error: false,
    emptyState: true,
    placeholder: undefined,
    disabled: false,
  }
)

const { label, required, valueField, labelField, items } = toRefs(props)

const emit = defineEmits(['update:modelValue'])
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const attrs = useAttrs()
</script>

<template>
  <div class="flex min-w-32 flex-col">
    <h6
      v-if="label"
      class="mb-2 text-sm font-bold"
      :class="{ '!text-error': error, '!text-grey-4': !error && disabled }"
    >
      {{ label }} {{ required ? '*' : '' }}
    </h6>
    <div class="relative flex">
      <select
        v-bind="attrs"
        v-model="value"
        :disabled="loading || disabled"
        class="!w-full rounded-xl border-[1px] p-1 pl-2 outline-none transition-all duration-100 after:text-grey-2"
        :class="`${
          error
            ? ' animate-nudge border-error'
            : 'border-grey-4 focus:border-grey-2'
        } ${loading ? 'bg-transparent text-grey-4' : 'bg-white text-grey-2'} ${value ? 'focus:text-black-0' : 'text-grey-4'}`"
      >
        <option
          v-if="emptyState || placeholder"
          :value="undefined"
          class="text-grey-6"
          hidden
        >
          {{ placeholder }}
        </option>
        <option :value="undefined"></option>
        <option
          v-for="(item, index) in items"
          :key="index"
          :class="`option-${index} text-grey-2`"
          :value="item[valueField as keyof T]"
        >
          {{ item[labelField as keyof T] }}
        </option>
      </select>
    </div>
  </div>
</template>
<style>
.animate-nudge {
  animation: nudge 0.4s linear;
}
@keyframes nudge {
  0% {
    transform: rotate(-1deg);
  }
  33% {
    transform: rotate(1deg);
  }
  66% {
    transform: rotate(-1deg);
  }
}
</style>
