import { App, Component } from 'vue'

export async function registerGlobalComponents(app: App) {
  const components = import.meta.glob('./*.vue', { eager: true }) as Record<
    string,
    { default: Component }
  >
  const layouts = import.meta.glob('../layouts/*.vue', {
    eager: true,
  }) as Record<string, { default: Component }>

  const modules = { ...components, ...layouts }
  Object.keys(modules).forEach(async (path) => {
    const pathArray = path.split('/')
    const partialName = pathArray[pathArray.length - 1]
    if (!partialName)
      throw new Error('Problem loading component on path ' + path)
    const componentName = partialName.split('.')[0]
    app.component(componentName, modules[path].default)
  })
}
