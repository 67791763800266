<script setup lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'
import orderIconDown from '@/assets/icons/green/order_down.svg'
import orderIconUp from '@/assets/icons/green/order_up.svg'
import arrowIcon from '@/assets/icons/grey/arrow_down.svg'
import orderIconGrey from '@/assets/icons/grey/order.svg'
import { ref, watch } from 'vue'

const props = defineProps<{
  modelValue: string
  order: string
  orderingOptions: { value: string; label: string }[]
  multiple: boolean
  isSecondary: {
    type: boolean
    default: false
  }
}>()

const emit = defineEmits(['update-order', 'update:modelValue'])

const localValue = ref(props.modelValue)

watch(localValue, (newValue) => {
  emit('update:modelValue', newValue)
})
</script>
<template>
  <template v-if="isSecondary">
    <hr class="my-5" />
    <h3 class="pb-3 font-bold">
      {{ $t('corporate.order.modalSecondLabel') }}
    </h3>
  </template>
  <div
    :class="multiple ? 'flex-col gap-5' : 'flex-row'"
    class="flex"
    @click.stop
  >
    <Listbox v-model="localValue">
      <ListboxButton
        :id="isSecondary ? 'secondary-searchBy' : 'searchBy'"
        class="relative flex !w-[24rem] items-center justify-between rounded-xl border-[1px] p-1 pl-2 outline-none transition-all duration-100 focus:text-black-0"
      >
        <span>
          <p v-if="modelValue">{{ modelValue }}</p>
          <p v-else class="text-grey-5">
            {{ $t('corporate.order.title') }}
          </p>
        </span>
        <img :src="arrowIcon" class="h-5 w-5" />
      </ListboxButton>
      <ListboxOptions
        class="absolute top-24 z-50 mt-1 max-h-60 w-80 overflow-auto border-[1px] border-black bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
      >
        <ListboxOption
          v-for="(option, index) in orderingOptions"
          :id="`order-options-${index}`"
          :key="option.value"
          class="z-50 mx-4 cursor-pointer py-1 text-grey-2"
          :class="
            index + 1 < orderingOptions.length
              ? 'border-b-[1px] border-b-grey-5'
              : ''
          "
          :value="option.label"
        >
          {{ option.label }}
        </ListboxOption>
      </ListboxOptions>
    </Listbox>
    <div v-if="multiple" class="flex flex-row">
      <img
        id="order-button-desc"
        :src="order === 'desc' ? orderIconUp : orderIconGrey"
        width="25"
        class="mx-2 w-10 rounded-full border p-2"
        @click="$emit('update-order', order === 'asc' ? 'desc' : 'asc')"
      />
      <img
        id="order-button-asc"
        :src="order === 'asc' ? orderIconDown : orderIconGrey"
        width="25"
        class="mx-2 w-10 rounded-full border p-2"
        @click="$emit('update-order', order === 'asc' ? 'desc' : 'asc')"
      />
    </div>
    <img
      v-else
      id="order-button"
      :src="order === 'desc' ? orderIconUp : orderIconDown"
      width="25"
      class="mx-2"
      @click="$emit('update-order', order === 'asc' ? 'desc' : 'asc')"
    />
  </div>
</template>
