<script setup lang="ts">
import galleryIcon from '@/assets/icons/green/galery.svg'
import deleteIcon from '@/assets/icons/grey/close.svg'
import photoIcon from '@/assets/icons/white/camera.svg'

import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import CropDialog from './CropDialog.vue'

const DEFAULT_ALLOWED_IMAGE_TYPE = 'image/jpeg'

const props = defineProps<{
  accept?: string
  filePreview?: string
  type?: string
}>()
const emit = defineEmits(['change', 'save', 'delete'])

const localFile = ref()
const localFilePreview = ref()
const reader = new FileReader()
const { t } = useI18n()
const toast = useToast()

const dialogs = reactive({
  cropPicture: false,
})

const preview = computed(() =>
  localFilePreview.value
    ? localFilePreview.value
    : props.filePreview?.includes('http')
      ? props.filePreview
      : `${import.meta.env.VITE_API_URL}/resources/${props.filePreview}`
)

const hasFile = computed(() => localFile.value || props.filePreview)

function removefile() {
  localFile.value = undefined
  localFilePreview.value = undefined
  emit('delete', localFile.value)
}

function handleDrop(event: DragEvent) {
  event.preventDefault()

  const files = event.dataTransfer?.files

  if (files && files.length > 0) {
    const newFile = files[0]

    reader.onload = () => {
      localFilePreview.value = reader.result as string
      localFile.value = newFile
    }
    reader.readAsDataURL(newFile)
  }
}

function handleFileInput(event: Event) {
  const input = event.target as HTMLInputElement
  if (input.files && input.files.length > 0) {
    const newFile = input.files[0]
    if (
      props.accept
        ? !props.accept
            ?.split(',')
            .map((i) => i.trim() || 'jpeg')
            .includes(newFile.type)
        : DEFAULT_ALLOWED_IMAGE_TYPE !== newFile.type
    ) {
      toast.error(t('general.uploadError'))
      return
    }
    reader.onload = () => {
      localFilePreview.value = reader.result as string
      localFile.value = newFile
    }
    reader.readAsDataURL(newFile)
  }
  input.value = ''
}

function openFileInput(): void {
  const inputFile = document.getElementById('file-input')

  if (inputFile) {
    const fileSelectedPromise = new Promise<void>((resolve) => {
      inputFile.addEventListener(
        'change',
        () => {
          resolve()
        },
        { once: true }
      )

      inputFile.click()
    })

    fileSelectedPromise.then(() => {
      dialogs.cropPicture = true
    })
  }
}

function handleCrop(image: string) {
  localFilePreview.value = image
  dialogs.cropPicture = false

  const url = image
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const newFile = new File([blob], 'Filename', { type: 'image/jpeg' })
      localFile.value = newFile
      emit('change', localFile.value)
    })
}
</script>

<template>
  <input
    id="file-input"
    type="file"
    name="inputFileTerms"
    class="hidden"
    :accept="props.accept || 'image/jpeg'"
    @change="handleFileInput"
  />
  <div
    v-if="!hasFile"
    class="drag-drop-container border-spacing[40] flex h-24 w-full cursor-pointer flex-col items-center justify-evenly rounded-md border-4 border-dashed border-gray-300 bg-white"
    @dragover.prevent
    @drop="handleDrop"
    @click="openFileInput"
  >
    <img :src="galleryIcon" width="25" class="mr-2" />

    <p class="text-xs font-bold">{{ $t('general.choseFileImage') }}</p>
    <p class="text-xs font-light">{{ $t('general.clickOrDrag') }}</p>
    <p class="text-xs">
      {{ $t('general.allowedImageFormats', { formats: 'jpeg' }) }}
    </p>
  </div>
  <div
    v-else
    class="flex h-24 w-full items-center justify-center rounded-lg bg-white p-4"
  >
    <div
      class="relative flex h-auto max-h-24 w-24 flex-shrink-0 overflow-hidden"
    >
      <img
        id="file-input-preview"
        :src="preview"
        class="h-auto w-24 !rounded-xl p-2"
      />

      <img
        :src="deleteIcon"
        class="absolute -right-3 top-5 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white"
        @click="removefile"
      />
    </div>
    <div class="flex-grow pl-4">
      <Button @click="openFileInput">
        <img :src="photoIcon" />
        {{ $t('corporate.edit.changePicture') }}
      </Button>
    </div>
  </div>
  <CropDialog
    v-model:open="dialogs.cropPicture"
    :image="typeof reader.result == 'string' ? reader.result : undefined"
    :type="type"
    @close="(dialogs.cropPicture = false), removefile()"
    @save="handleCrop"
  />
</template>
