<script setup lang="ts">
import { computed, watch } from 'vue'

const props = defineProps<{
  modelValue?: number
  length: number
  resetPagination?: boolean
}>()

const emits = defineEmits(['update:modelValue', 'pagination-reset-complete'])

const value = computed({
  get() {
    return props.modelValue || 1
  },
  set(newValue: number) {
    if (newValue < 1 || newValue > props.length) return
    emits('update:modelValue', newValue)
  },
})

const pagesToShow = computed(() => {
  const totalPages = props.length
  const currentPage = value.value
  const temp: string[] = []

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      temp.push(String(i))
    }
  } else {
    temp.push('1')

    if (currentPage > 3) {
      temp.push('...')
    }
    if (currentPage <= 3) {
      for (let i = 2; i <= 4; i++) {
        temp.push(String(i))
      }
    } else if (currentPage >= totalPages - 2) {
      for (let i = totalPages - 3; i <= totalPages - 1; i++) {
        temp.push(String(i))
      }
    } else {
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        temp.push(String(i))
      }
    }
    if (currentPage < totalPages - 2) {
      temp.push('...')
    }

    temp.push(String(totalPages))
  }

  return temp
})

watch(
  () => props.resetPagination,
  (newVal) => {
    if (newVal) {
      emits('update:modelValue', 1)
      emits('pagination-reset-complete')
    }
  }
)
</script>

<template>
  <div class="flex justify-center align-baseline">
    <button
      v-for="(page, index) in pagesToShow"
      :id="`button-page-${page}`"
      :key="index"
      :class="[
        'mt-0.5',
        'flex',
        'h-6',
        'w-6',
        'justify-center',
        'align-middle',
        'text-sm',
        'font-sans',
        'font-bold',
        'rounded-sm',

        { 'bg-green': page == String(value) },
      ]"
      @click="value = Number(page)"
    >
      {{ page }}
    </button>
  </div>
</template>
