<script setup lang="ts">
import alertIcon from '@/assets/icons/white/attention.svg'
import AlertDialog from '@/components/AlertDialog.vue'
import { ref } from 'vue'

const props = defineProps<{ handleConfirm: () => void }>()

const isOpen = ref(false)

function open() {
  isOpen.value = true
}
function close() {
  isOpen.value = false
}
</script>

<template>
  <Button
    id="save-edit-corporative-account"
    class="bg-grey-3"
    type="button"
    @click="open"
    >{{ $t('general.cancel') }}</Button
  >
  <AlertDialog
    :is-open="isOpen"
    :icon="alertIcon"
    color="alert"
    :text="$t('corporate.newRole.cancelDialogMessage')"
    :title="$t('general.advise')"
    class="flex !min-w-full flex-col !px-10 text-sm"
    @close="close"
  >
    <div class="flex gap-2">
      <Button
        class="my-4 !min-h-[2.5rem] !min-w-[10rem] bg-grey-3 text-base"
        @click="close"
        >{{ $t('general.back') }}</Button
      >
      <Button
        class="my-4 !min-h-[2.5rem] !min-w-[10rem] !bg-alert text-base"
        @click="props.handleConfirm"
        >{{ $t('general.exit') }}</Button
      >
    </div>
  </AlertDialog>
</template>
