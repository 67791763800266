import { MemberService } from '@/modules/member/service/member'
import {
  Member,
  MemberFilters,
  MemberRequestParams,
  PermissionRequestParams,
} from '@/modules/member/types/members'
import { defineStore, storeToRefs } from 'pinia'
import { reactive, ref, watch } from 'vue'

import { QueryParams } from '@/services/types'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'
import { InviteService } from './service/invite'
import { RoleService } from './service/role'
import { FilterParams, Invite } from './types/invite'
import { Role } from './types/role'

import { useCorporativeAccount } from '@/store/corporative'

export const useMember = defineStore('member', () => {
  const loading = ref<boolean>(false)
  const members = ref<Member[]>([])
  const permissions = ref<string[]>([])
  const invites = ref<Invite[]>([])
  const roles = ref<Role[]>([])
  const selectedRole = ref<Role>()
  const selectedInviteEmail = ref<string>('')
  const toast = useToast()
  const { t } = useI18n()
  const selectedMemberPermissions = ref<string[]>([])
  const selectedInvitePermissions = ref<string[]>([])

  const accountStore = useCorporativeAccount()
  const { accountPermissions, isAccountOwner, selectedAccount } =
    storeToRefs(accountStore)

  const memberFilters: MemberFilters = reactive({
    emptyRole: false,
    customRoles: false,
    defaultRoles: false,
    listAllPermissions: [],
  })

  async function getMemberPicture(firstName: string, lastName: string) {
    const profilePicture = `https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=random&size=512`
    return profilePicture
  }

  const inviteFilters: FilterParams = reactive({
    customRoles: false,
    defaultRoles: false,
    emptyRoles: false,
    inviteRequest: false,
    pendingInvite: false,
    listAllPermissions: [],
  })

  const route = useRoute()
  async function getMembers(memberRequestParams: MemberRequestParams) {
    const hasMemberReadPermission = accountPermissions.value.some(
      (permission) => permission === 'MEMBER_READ'
    )

    if (!hasMemberReadPermission && !isAccountOwner.value) {
      return {}
    }
    const response = await MemberService.getMembers({
      ...memberFilters,
      ...memberRequestParams,
    })
    const { data, error } = response

    if (!hasMemberReadPermission && !isAccountOwner.value) {
      return {}
    }

    if (error || !data) {
      toast.error(t('corporate.member.getMembersFailure'))
      return { error }
    }
    members.value = data

    if (data) {
      members.value.forEach(async (member) => {
        member.profilePicture = await getMemberPicture(
          member.firstName,
          member.lastName
        )
      })
    }
    return response
  }

  async function getMemberPermission(memberId: string) {
    const { data, error } = await MemberService.getMemberPermission(
      route.params.id as string,
      memberId
    )

    if (error || !data) return
    selectedMemberPermissions.value = data
  }

  async function updateMemberPermission(memberId: string, payload: string[]) {
    const { data, error } = await MemberService.updateMemberPermission(
      route.params.id as string,
      memberId,
      payload
    )
    return { data, error }
  }

  async function getInvites(params?: QueryParams) {
    const hasMemberReadPermission = accountPermissions.value.some(
      (permission) =>
        permission === 'MEMBER_INVITE' || permission === 'MEMBER_READ'
    )

    if (!hasMemberReadPermission && !isAccountOwner.value) {
      return {}
    }
    const response = await InviteService.getInvites(
      { ...inviteFilters },
      route.params.id as string,
      params
    )
    const { data, error } = response

    if (error || !data) {
      toast.error(t('corporate.invites.getInvitesFailure'))
      return { error }
    }
    invites.value = data
    return response
  }

  async function searchInvites(params?: QueryParams) {
    const { data } = await InviteService.getInvites(
      { ...inviteFilters },
      route.params.id as string,
      params
    )
    return data
  }

  async function searchMember({
    id,
    searchTerm,
  }: {
    id: string
    searchTerm: string
  }) {
    const { data, error } = await MemberService.getMembers({ id, searchTerm })
    if (error || !data) {
      toast.error(t('corporate.member.getMembersFailure'))
      return
    }
    return data
  }

  async function listRoles(PermissionRequestParams: PermissionRequestParams) {
    const response = await RoleService.listRoles(PermissionRequestParams)
    const { data, error } = response

    if (error || !data) return { error }
    roles.value = data
    return response
  }

  async function sendMemberInvitations(id: string, emailsList: string[]) {
    const { data, error } =
      await MemberService.sendMemberInviteForCorporativeAccount(id, emailsList)
    if (error || !data) {
      return
    }
    return { data, error }
  }

  async function sendRequestedEmail(
    id: string,
    requestId: string,
    email: string
  ) {
    const { data, error } = await MemberService.sendRequestedEmail(
      id,
      requestId,
      email
    )
    if (error || !data) {
      return
    }
    return { data, error }
  }

  async function createRole(id: number, payload: Partial<Role>) {
    return await RoleService.createRole(id, payload)
  }

  async function cancelInvite(idCorporate: string, idInvite: number) {
    return await InviteService.cancelInvite(idCorporate, idInvite)
  }
  async function acceptInvite(corporateInviteKey: string) {
    return await InviteService.acceptInvite(corporateInviteKey)
  }
  async function updateMemberRole(
    corporateId: string,
    memberId: string,
    roleId: number
  ) {
    const { error } = await MemberService.updateMemberRole({
      corporateId,
      memberId,
      roleId,
    })
    if (error) return
    toast.success(t('corporate.member.successSetRole'))
  }

  async function getRole(id: string, presetId: string) {
    const { data, error } = await RoleService.getRole(id, presetId)
    if (error || !data) return
    selectedRole.value = data
  }

  async function updateRole(id: number, payload: Partial<Role>) {
    return await RoleService.updateRole(id, payload)
  }

  async function deleteRole(id: string, roleId: string) {
    const response = await RoleService.deleteRole(id, roleId)
    return response
  }

  async function listPermissions() {
    const { data } = await RoleService.listPermissions()
    permissions.value = data || []
    return data
  }

  async function listInvitePermissions(id: string, inviteId: string) {
    const { data = [], error } = await InviteService.listInvitePermissions(
      id,
      inviteId
    )
    selectedInvitePermissions.value = data
    if (error) toast.error(t('corporate.member.listInvitePermissionsError'))
    return data
  }
  async function updateInvitePermissions(
    id: string,
    inviteId: string,
    permissions: string[]
  ) {
    const { data, error } = await InviteService.updateInvitePermissions(
      id,
      inviteId,
      permissions
    )
    if (error) toast.error(t('corporate.member.updateInvitePermissionsError'))
    return { data, error }
  }

  async function assignRoleToInvite(
    id: string,
    inviteId: string,
    roleId: string
  ) {
    const { data, error } = await InviteService.assignRoleToInvite(
      id,
      inviteId,
      roleId
    )
    if (error) toast.error(t('corporate.member.assignRoleToInviteError'))
    return { data, error }
  }

  async function deleteMember(id: string, memberId: string) {
    const { data, error } = await MemberService.deleteMember(id, memberId)
    toast.success(t('corporate.member.successDeleteMember'))
    return { data, error }
  }

  async function resetMemberFilter() {
    memberFilters.customRoles = false
    memberFilters.defaultRoles = false
    memberFilters.emptyRole = false
    memberFilters.listAllPermissions = []
  }

  async function resetInviteFilter() {
    inviteFilters.customRoles = false
    inviteFilters.defaultRoles = false
    inviteFilters.emptyRoles = false
    inviteFilters.listAllPermissions = []
    inviteFilters.pendingInvite = false
  }

  watch(selectedAccount, () => {
    resetMemberFilter()
    resetInviteFilter()
  })

  return {
    loading,
    getMembers,
    searchMember,
    members,
    permissions,
    roles,
    selectedRole,
    listRoles,
    sendMemberInvitations,
    sendRequestedEmail,
    createRole,
    updateMemberRole,
    invites,
    getInvites,
    searchInvites,
    cancelInvite,
    acceptInvite,
    getRole,
    updateRole,
    deleteRole,
    listPermissions,
    getMemberPermission,
    updateMemberPermission,
    selectedMemberPermissions,
    listInvitePermissions,
    updateInvitePermissions,
    selectedInvitePermissions,
    selectedInviteEmail,
    assignRoleToInvite,
    memberFilters,
    inviteFilters,
    deleteMember,
  }
})
