import productionWhiteIcon from '@/assets/icons/white/producao.svg'
import scheduleWhiteIcon from '@/assets/icons/white/atv_ag.svg'
import participantsWhiteIcon from '@/assets/icons/white/participantes.svg'
import communicationWhiteIcon from '@/assets/icons/white/comunicacao.svg'
import financeWhiteIcon from '@/assets/icons/white/orcamento.svg'

import productionGreenIcon from '@/assets/icons/green/producao.svg'
import scheduleGreenIcon from '@/assets/icons/green/atv_ag.svg'
import participantsGreenIcon from '@/assets/icons/green/participantes.svg'
import communicationGreenIcon from '@/assets/icons/green/comunicacao.svg'
import financeGreenIcon from '@/assets/icons/green/orcamento.svg'

export const sidebarItemIcons = {
  production: [productionWhiteIcon, productionGreenIcon],
  schedule: [scheduleWhiteIcon, scheduleGreenIcon],
  participants: [participantsWhiteIcon, participantsGreenIcon],
  communication: [communicationWhiteIcon, communicationGreenIcon],
  financial: [financeWhiteIcon, financeGreenIcon],
}
