import { useCorporativeAccount } from '@/store/corporative'
import { storeToRefs } from 'pinia'
import { Directive, watch } from 'vue'

const handleClick = (e: Event) => {
  e.stopPropagation()
  e.preventDefault()
}

const resetDisabledState = (el: HTMLButtonElement) => {
  if (el.hasAttribute('disabled')) {
    el.disabled = false
  } else {
    el.style.pointerEvents = 'auto'
    el.style.opacity = '1.0'
    el.removeEventListener('click', handleClick)
  }
}

export const vPermission: Directive = (el, binding) => {
  const corporativeAccountStore = useCorporativeAccount()
  const { corporate, isAccountOwner, accountPermissions } = storeToRefs(
    corporativeAccountStore
  )
  const requiredPermissions = binding.value ? (binding.value as string[]) : []

  watch(corporate, () => {
    if (!corporate) return
    if (isAccountOwner.value || !requiredPermissions.length) {
      resetDisabledState(el)
      return
    }

    for (const permission of requiredPermissions) {
      if (!accountPermissions.value.find((p: string) => p === permission)) {
        if (el.hasAttribute('disabled')) {
          el.disabled = true
        } else {
          el.style.pointerEvents = 'none'
          el.style.opacity = '0.3'
          el.addEventListener('click', handleClick)
        }
        return
      }
    }
    resetDisabledState(el)
  })
}
