import { RouteRecordRaw } from 'vue-router'

export const memberRoutes: RouteRecordRaw[] = [
  {
    path: '/account/:id/',
    component: () => import('@/layouts/Main.vue'),
    meta: { requiredPermission: 'MEMBER_READ' },
    children: [
      {
        path: 'members',
        name: 'members-list',
        component: () => import('./views/Member.vue'),
      },
      {
        path: 'edit-members-permission/:memberId/:memberName',
        name: 'edit-members-permission',
        component: () => import('./views/MemberPermissionEditor.vue'),
        meta: { requiredPermission: ['MEMBER_PERMISSIONS'] },
      },
      {
        path: 'roles',
        name: 'roles-list',
        component: () => import('./views/Roles.vue'),
        meta: { requiredPermission: ['MEMBER_READ'] },
      },
      {
        path: 'invites',
        name: 'invites-list',
        component: () => import('./views/Invites.vue'),
        meta: { requiredPermission: ['MEMBER_READ'] },
      },
      {
        path: 'invites/:inviteId/permissions',
        name: 'edit-invite-permissions',
        component: () => import('./views/InvitePermissionsEditor.vue'),
        meta: { requiredPermission: ['MEMBER_PERMISSIONS'] },
      },
      {
        path: 'new-role',
        name: 'new-role',
        component: () => import('./views/NewRole.vue'),
        meta: { requiredPermission: ['MEMBER_PERMISSIONS'] },
      },
      {
        path: 'edit-role/:roleId',
        name: 'edit-role',
        component: () => import('./views/RoleDetail.vue'),
        meta: { requiredPermission: ['MEMBER_PERMISSIONS'] },
      },
      {
        path: 'filter-members',
        name: 'filter-members',
        component: () => import('./views/FilterMembers.vue'),
      },
      {
        path: 'filter-invites',
        name: 'filter-invites',
        component: () => import('./views/FilterInvites.vue'),
        meta: { requiredPermission: ['MEMBER_INVITE'] },
      },
    ],
  },
]
