<script setup lang="ts">
import { RouteLocationRaw, useRouter } from 'vue-router'
import backArrow from '@/assets/icons/back.svg'
const router = useRouter()

defineProps<{ to?: RouteLocationRaw }>()
</script>
<template>
  <button v-bind="$attrs" @click="() => (to ? router.push(to) : router.back())">
    <img :src="backArrow" alt="" />
  </button>
</template>
