import { defineRule } from 'vee-validate'
import { computed, reactive } from 'vue'
import { z } from 'zod'

export const usePostalCodeMask = () => {
  const countryMask = reactive({
    regex: '',
    format: '',
  })
  const maskOptions = computed(() => ({
    mask: countryMask.format,
  }))

  defineRule('postalCodeRegex', (value: string) => {
    if (!value) {
      return true
    }
    if (countryMask.regex) {
      const regex = new RegExp(countryMask.regex)
      return regex.test(value) || 'validation.postalCode'
    }

    return true
  })

  const postalCodeZodRule = z.custom<'postalCodeRegex'>((value) => {
    if (!value) {
      return true
    }
    if (countryMask.regex) {
      const regex = new RegExp(countryMask.regex)
      return typeof value === 'string' ? regex.test(value) : false
    }

    return true
  }, 'validation.postalCode')

  return { countryMask, maskOptions, postalCodeZodRule }
}
