import { City, Country, State } from '@/types/address'
import { CRUDService } from './CRUD'

export class AddressService extends CRUDService {
  static async getCountries() {
    return await this.handlePromise<Country[]>(
      this.api.get<Country[]>(`/address/countries`)
    )
  }
  static async getCountryStates(countryCode: string) {
    return await this.handlePromise<State[]>(
      this.api.get<State[]>(`/address/states?countryCode=${countryCode}`)
    )
  }
  static async getStateCities(stateCode: string) {
    return await this.handlePromise<City[]>(
      this.api.get<City[]>(`/address/cities?stateCode=${stateCode}`)
    )
  }
}
